import React from 'react';
import styled from 'styled-components';
import LoginInfo from './LoginInfo';
import ProfileInfo from './ProfileInfo';
import Registration from './Registration';
import ChangePasswordModal from '../../common/Modals/ChangePasswordModal';

const OverView = () => {
  return (
    <MainWrapper>
      <h1>Account Overview</h1>
      <Content>
        <LoginInfo />
        <ProfileInfo />
        <Registration />
      </Content>
      <ChangePasswordModal />
    </MainWrapper>
  );
};

export default OverView;

const MainWrapper = styled.div`
  color: ${props => props.theme.colors.white_main};
  margin: 60px;

  .overview-section {
    margin-top: 20px;

    &-container {
      margin-top: 20px;
    }

    &-header {
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0.86px;
    }

    &-data {
      font-weight: 500;
      margin-top: 5px;
      font-size: 16px;
      line-height: 1.5;

      &-sub {
        font-weight: 400;
      }
    }
  }

  .button {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.86px;
    color: ${props => props.theme.colors.orange_main};
    cursor: pointer;
    margin-top: 20px;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
`;
