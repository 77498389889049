import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import UniversalButton from '../UniversalButton';
import {setUser} from '../../../redux/actions/user';
import styled from 'styled-components';
import {user} from '../../../utilities/apiCalls';

const PromoCode = props => {
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.id);
  const [promoInput, setPromoInput] = useState('');
  const [promoSubmitMsg, setPromoSubmitMsg] = useState('');

  const handlePromoInputChange = event => {
    setPromoInput(event.target.value);
    if (promoInput.length === 0 && promoSubmitMsg === 'invalid') {
      setPromoSubmitMsg('');
    }
  };

  const handlePromoSubmit = async event => {
    event.preventDefault();
    const body = {
      promoCode: promoInput,
      userUUID: userID
    };

    user.submitSerialPromo(body).then(payload => {
      if (payload.data) {
        setPromoSubmitMsg('success');
        user.getUserInfo(userID).then(userPayload => {
          dispatch(setUser(userPayload.data));
        });
        setTimeout(() => {
          setPromoInput('');
          setPromoSubmitMsg('');
          props.setShowPromoModal(false);
          props.setShowPromoSuccessModal(true);
        }, 1000);
      } else {
        setPromoSubmitMsg('invalid');
      }
    });
  };

  return props.showPromoModal ? (
    <Background>
      <Modal>
        <div className="title">Add a promo code</div>
        <PromoInput>
          <div
            className="close-modal"
            onClick={() => {
              setPromoInput('');
              setPromoSubmitMsg('');
              props.setShowPromoModal(false);
            }}>
            X
          </div>
          <form className="promo-form" onSubmit={handlePromoSubmit}>
            <label className="promo-label">
              <div>
                Promo Code
                {promoSubmitMsg === 'success' && (
                  <span> - Applied Successfully!</span>
                )}
              </div>
              <input
                className={
                  promoSubmitMsg === 'invalid' && promoInput.length
                    ? 'promo-input promo-input-error'
                    : 'promo-input'
                }
                value={promoInput}
                onChange={handlePromoInputChange}
                type="text"
              />
              {promoSubmitMsg === 'invalid' && promoInput.length ? (
                <span className="promo-invalid-message promo-invalid-message-background">
                  Promo code does not exist
                </span>
              ) : (
                <span className="promo-invalid-message"></span>
              )}
            </label>
            <UniversalButton
              type={'submit'}
              className={
                promoInput.length < 10
                  ? 'promo-btn-submit promo-btn-submit-disabled'
                  : 'promo-btn-submit'
              }
              disabled={promoInput.length < 10}
              label={'Apply Promo Code'}
            />
          </form>
        </PromoInput>
      </Modal>
    </Background>
  ) : null;
};

export default PromoCode;

const Background = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  background-color: ${props => props.theme.colors.grey_base}CF;
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 450px;
  top: calc(50% - 100px);
  left: calc(50% - 225px);
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  @media (max-width: 450px) {
    max-width: 100%;
    top: 20vh;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .title {
    font-size: 20px;
    margin-top: 30px;
    margin-left: 30px;
    line-height: 1.3;
    align-self: flex-start;
  }
`;

const PromoInput = styled.div`
  margin-top: 40px;
  max-width: 450px;

  .close-modal {
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .promo {
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &-btn-submit {
      margin: 30px auto 30px auto;
      padding: 12px;
      cursor: pointer;
      min-height: 50px;
      background-color: ${props => props.theme.colors.red_main};
      width: 260px;
      color: ${props => props.theme.colors.white_main};
      font-size: 16px;
      border-radius: 4px;
      border: none;

      @media (max-width: 450px) {
        width: 220px;
      }

      &-disabled {
        opacity: 0.4;
        cursor: not-allowed;
      }
    }

    &-label {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: ${props => props.theme.colors.white_main};
      font-weight: 600;
      letter-spacing: 0.86ps;
    }

    &-input {
      font-size: 16px;
      width: 390px;
      margin: 3px 0px 0px 0px;
      height: 50px;
      border-radius: 5px;
      border: solid 1px ${props => props.theme.colors.white_main};
      color: ${props => props.theme.colors.white_main};
      padding-left: 10px;
      box-sizing: border-box;
      display: inline-block;
      background: ${props => props.theme.colors.grey_4} !important;

      @media (max-width: 450px) {
        width: 300px;
      }

      &-error {
        border: solid 1px ${props => props.theme.colors.red_error};
      }
    }

    &-invalid-message {
      color: ${props => props.theme.colors.white_main};
      height: 15px;
      padding: 0px 5px 3px 5px;
      margin-top: 2px;
      border-radius: 4px;
      font-size: 12px;
      align-self: flex-start;

      &-background {
        background-color: ${props => props.theme.colors.red_error};
      }
    }
  }
`;
