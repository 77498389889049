import './index.css';

import {BrowserRouter, Route, Switch} from 'react-router-dom';

import Account from './components/account';
import CancelThanks from './components/account/subscription/CancelThanks';
import ChangePassword from './components/account/changePassword';
import Classes from './components/classes';
import Created from './components/register/Created';
import DataDogContainer from './components/classes/common/DataDogContainer';
import EditProfile from './components/account/editProfile';
import {Elements} from '@stripe/react-stripe-js';
import HaveEquipment from './components/register/HaveEquipment';
import Login from './components/Login';
import MainTheme from './assets/styles/MainTheme';
import NoMatch from './components/common/NoMatch';
import Payment from './components/register/payment';
import ProtectedRoute from './components/common/ProtectedRoute';
import {Provider} from 'react-redux';
import React from 'react';
import ReactDOM from 'react-dom';
import Register from './components/register/createAccount';
import ResetPassword from './components/account/resetPassword';
import SerialNumber from './components/register/serialNumber';
import {ThemeProvider} from 'styled-components';
import Verification from './components/register/verification';
import VideoPage from './components/VideoPage';
import configStore from './redux/configStore';
import {loadStripe} from '@stripe/stripe-js';

const key = window.location.href.includes('beta')
  ? 'pk_test_vL2SQyvYSyHYWmbNSV9eDgp20026x9Lapj'
  : 'pk_live_IvLjESqmmvCm79KNI02GoPil00qWPXPOCH';

const stripePromise = loadStripe(key);

ReactDOM.render(
  <Provider store={configStore}>
    <ThemeProvider theme={MainTheme}>
      <Elements stripe={stripePromise}>
        <DataDogContainer />
        <BrowserRouter>
          <Switch>
            <Route path="/login" component={Login} />
            <Route exact path="/reset-password" component={ResetPassword} />

            <Route exact path="/subscription" component={Register} />
            <Route
              path="/subscription/verification/:email"
              component={Verification}
            />
            <Route
              exact
              path="/subscription/equipment"
              component={HaveEquipment}
            />
            <Route
              exact
              path="/subscription/equipment/serial"
              component={SerialNumber}
            />
            <Route exact path="/subscription/payment" component={Payment} />
            <Route exact path="/subscription/created" component={Created} />

            <ProtectedRoute exact path="/video" component={Classes} />
            <Route
              exact
              path="/account/change-password"
              component={ChangePassword}
            />
            <ProtectedRoute exact path="/account" component={Account} />
            <ProtectedRoute
              exact
              path="/account/profile"
              component={EditProfile}
            />
            <ProtectedRoute
              exact
              path="/account/subscription/cancel/thanks"
              component={CancelThanks}
            />
            <ProtectedRoute path="/video/:classID" component={VideoPage} />
            <Route component={NoMatch} />
          </Switch>
        </BrowserRouter>
      </Elements>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
