import React, {useState} from 'react';
import styled from 'styled-components';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement
} from '@stripe/react-stripe-js';
import {useDispatch, useSelector} from 'react-redux';
import createFormStyles from '../../../../assets/styles/createFormStyles';
import UniversalButton from '../../../common/UniversalButton';
import {setPaymentState} from '../../../../redux/actions/registration';
import {textButton} from '../../../../assets/styles/common';
import successCheckIcon from '../../../../assets/images/icon-success-green.svg';
import {getColors} from '../../../../assets/styles/colors';

const CardForm = () => {
  const colors = getColors();
  const dispatch = useDispatch();
  const billingInfo = useSelector(state => state.registration.billingInfo);
  const paymentMethod = useSelector(state => state.user.paymentMethod);
  const styles = createFormStyles();
  const [cardErrors, setCardErrors] = useState({
    card: [false, ''],
    date: [false, ''],
    cvc: [false, '']
  });
  const showSubmitMessage = 'hidden';

  const cardInfoError = (field, error) => {
    const errorObj = {error};
    const realError = errorObj.error.error;
    const newError = cardErrors;

    if (realError !== undefined) {
      newError[field][0] = true;
      newError[field][1] = realError.message;
      setCardErrors(newError);
    } else {
      newError[field][0] = false;
      newError[field][1] = '';
      setCardErrors(newError);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    // CardForm, AddPayment, Payment, Route would never get called
    // context is to disable apiCalls/createSubscription so we can deprecate payment-processor/auth-service
  };

  const handleShowSubmitMessage = () => {
    if (showSubmitMessage === 'success') {
      return (
        <Message>
          <object
            aria-label={'profile outline'}
            data={successCheckIcon}
            type="image/svg+xml"
          />
          <p className="success">Successful</p>
          <p className="success">{`Successfully ${
            !!paymentMethod ? 'updated' : 'added'
          } payment method!`}</p>
        </Message>
      );
    } else if (showSubmitMessage === 'error') {
      return (
        <Message>
          <div>
            <span className="error">Error updating. Please contact </span>
            <a
              style={{color: colors.red_error}}
              href="https://inspirefitness.com/support"
              target="_blank"
              rel="noreferrer">
              support
            </a>
          </div>
        </Message>
      );
    } else {
      return <Message />;
    }
  };

  return (
    <div>
      {showSubmitMessage !== 'success' && (
        <Form onSubmit={handleSubmit}>
          <PrevStep>
            <p className={'label'}>1. Billing Information</p>
            <p>{billingInfo.name}</p>
            <p>{`${billingInfo.address_line1} ${billingInfo.address_line2}`}</p>
            <p>{`${billingInfo.address_city}, ${billingInfo.address_state} ${billingInfo.address_zip} ${billingInfo.address_country}`}</p>
            <p
              style={{
                ...textButton,
                padding: '20px 0',
                alignSelf: 'flex-end',
                color: colors.orange_main,
                fontSize: '1em',
                fontWeight: '600'
              }}
              onClick={() => dispatch(setPaymentState(''))}>
              Edit
            </p>
          </PrevStep>
          <p style={{...styles.subTitle, textAlign: 'left'}}>
            2. Payment Information
          </p>
          <InputStyle>
            <label className="input-label input-label--full">
              Card Number
              <div className="input-parent">
                <CardNumberElement
                  options={CARD_OPTIONS}
                  onChange={error => cardInfoError('card', error)}
                  placeholder=""
                />
              </div>
              {cardErrors.card[0] ? (
                <div className="text-message text-message--card">
                  {cardErrors.card[1]}
                </div>
              ) : (
                <div style={{height: '30px'}} />
              )}
            </label>
            <div className="input-label--group-card">
              <label style={{marginTop: '16px'}} className="input-label">
                Expiry Date (MM/YY)
                <div className="input-parent small">
                  <CardExpiryElement
                    options={CARD_OPTIONS}
                    onChange={error => cardInfoError('date', error)}
                    placeholder=""
                  />
                </div>
                {cardErrors.date[0] ? (
                  <div className="text-message text-message--card">
                    {cardErrors.date[1]}
                  </div>
                ) : (
                  <div style={{height: '40px'}} />
                )}
              </label>
              <label style={{marginTop: '16px'}} className="input-label">
                Security Code (CVV)
                <div className="input-parent small">
                  <CardCvcElement
                    options={CARD_OPTIONS}
                    onChange={error => cardInfoError('cvc', error)}
                    placeholder=""
                  />
                </div>
              </label>
            </div>
          </InputStyle>
          <div className="btn">
            <UniversalButton
              type={'submit'}
              processing={false}
              label={!paymentMethod ? 'ADD PAYMENT' : 'Edit Payment'}
            />
          </div>
        </Form>
      )}
      {handleShowSubmitMessage()}
    </div>
  );
};

export default CardForm;

const CARD_OPTIONS = {
  style: {
    base: {
      color: getColors().white_main,
      fontWeight: 500,
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {color: getColors().grey_4},
      '::placeholder': {color: getColors().grey_6}
    },
    invalid: {
      color: getColors().red_error
    }
  }
};

const Message = styled.div`
  min-height: 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const PrevStep = styled.div`
  border-bottom: 1px solid ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;

  & p {
    margin: 0;
  }

  & .label {
    margin-bottom: 20px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  @media (max-width: 450px) {
    width: 90vw;
  }
`;

const InputStyle = styled.div`
  max-width: 500px;
  margin: 0 auto;

  input {
    background: ${props => props.theme.colors.grey_4};
  }

  .small {
    // max-width: 100px;
  }

  .has-error {
    font-size: 16px;
    border: 1.5px solid ${props => props.theme.colors.red_error};
    border-radius: 5px;
    width: 100%;
    margin: 3px 0px 0 0px;
    height: 50px;
    color: ${props => props.theme.colors.white_main};
    box-sizing: border-box;
    padding-left: 10px;
  }

  .text-message {
    color: ${props => props.theme.colors.red_error};
    text-align: center;
    height: 40px;

    &--card {
      margin-bottom: 0;
      font-size: 0.75rem;
    }
  }

  .input {
    font-size: 16px;
    width: 100%;
    margin: 3px 0px 0px 0px;
    height: 50px;
    border-radius: 5px;
    border: solid 1px ${props => props.theme.colors.white_main};
    color: ${props => props.theme.colors.white_main};
    padding-left: 10px;
    box-sizing: border-box;

    &-label {
      display: inline-block;
      width: 100%;
      font-size: 12px;

      &--full {
        grid-column: 1 / span 2;
      }

      &--group {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5%;

        @media screen and (max-width: 424px) {
          grid-template-columns: 1fr;
        }

        &-card {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 5%;

          margin: 0 auto;
        }
      }
    }

    &-parent {
      width: 100%;
      margin: 3px 0px 0px 0px;
      height: 50px;
      border-radius: 5px;
      border: solid 1px ${props => props.theme.colors.white_main};
      background-color: ${props => props.theme.colors.grey_4};
      display: grid;
      align-content: center;
      padding-left: 10px;
      box-sizing: border-box;
    }
  }
  & input:-webkit-autofill {
    -webkit-text-fill-color: ${props => props.theme.colors.white_main};
    -webkit-box-shadow: 0 0 0px 1000px ${props => props.theme.colors.grey_4}
      inset;
  }

  & input:-moz-autofill {
    -moz-text-fill-color: ${props => props.theme.colors.white_main};
    -moz-box-shadow: 0 0 0px 1000px ${props => props.theme.colors.grey_4} inset;
  }
  & input:-o-autofill {
    -o-text-fill-color: ${props => props.theme.colors.white_main};
    -o-box-shadow: 0 0 0px 1000px ${props => props.theme.colors.grey_4} inset;
  }
  & input:-khtml-autofill {
    -khtml-text-fill-color: ${props => props.theme.colors.white_main};
    -khtml-box-shadow: 0 0 0px 1000px ${props => props.theme.colors.grey_4}
      inset;
  }
`;
