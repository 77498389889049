import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import styled from 'styled-components';
import {setUser} from '../../redux/actions/user';
import {user} from '../../utilities/apiCalls';
import {getCookie} from '../../utilities/common';
import Footer from '../common/Footer';
import TopLogo from '../common/TopLogo';
import UniversalButton from '../common/UniversalButton';

const HaveEquipment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const userID = useSelector(state => state.user.id);
  const userSubscription = useSelector(state => state.user.subscription);
  const [hasEquipment, setHasEquipment] = useState(false);

  useEffect(() => {
    const userID = getCookie('x-user');
    user.getUserInfo(userID).then(payload => dispatch(setUser(payload.data)));
  }, [dispatch]);

  useEffect(() => {
    if (userSubscription && !userSubscription.data) {
      user.createTrial().then(() => {
        user.getUserInfo(userID).then(payload => {
          dispatch(setUser(payload.data));
        });
      });
    }
  }, [dispatch, userID, userSubscription]);

  const createTrialAndRoute = () => {
    if (hasEquipment) {
      history.push('/subscription/equipment/serial');
    } else {
      history.push('/subscription/payment');
    }
  };

  return (
    <MainWrapper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
      <TopLogo style={{margin: '20px 0 0 0', width: 300}} />
      <div className={'sub-container'}>
        <h2>Continue set up</h2>
        <p>Do you have a piece of Inspire Fitness equipment?</p>
        <div className={'button-container'}>
          <button
            className={hasEquipment ? 'active' : ''}
            onClick={() => setHasEquipment(true)}>
            Yes
          </button>
          <button
            className={!hasEquipment ? 'active' : ''}
            onClick={() => setHasEquipment(false)}>
            No
          </button>
        </div>
        <UniversalButton
          onClick={() => createTrialAndRoute()}
          label={'Continue'}
        />
      </div>
      <Footer />
    </MainWrapper>
  );
};

export default HaveEquipment;

const MainWrapper = styled.div`
  & h2 {
    font-weight: 500;
  }

  .sub-container {
    text-align: center;
    margin: 0 auto;
    padding: 25px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    color: ${props => props.theme.colors.white_main};

    @media (max-width: 400px) {
      width: 100%;
      padding: 0;
    }
  }

  .terms-container {
    margin-top: 50px;
  }

  .terms-link {
    line-height: 1.3;
    font-size: 14px;
    text-decoration: none;
  }

  .button-container {
    display: flex;

    @media (max-width: 400px) {
      width: 100%;
      padding: 0;
      justify-content: space-around;
    }

    .active {
      background-color: ${props => props.theme.colors.teal_main}1F;
      border-color: ${props => props.theme.colors.teal_main};
    }

    & button {
      display: block;
      margin: 33px 24px;
      padding: 17px 56px;
      height: 64px;
      background-color: ${props => props.theme.colors.grey_3};
      width: 150px;
      color: ${props => props.theme.colors.white_main};
      font-size: 16px;
      border-radius: 8px;
      border: solid 1px ${props => props.theme.colors.grey_6};

      @media (max-width: 400px) {
        margin: 0;
      }
    }
  }
`;
