import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {useSelector} from 'react-redux';

const PromoSuccess = props => {
  const subInfo = useSelector(state => state.user.subscription);

  return props.showPromoSuccessModal && subInfo.data ? (
    <Background>
      <Modal>
        <div
          className="close-modal"
          onClick={() => props.setShowPromoSuccessModal(false)}>
          X
        </div>
        {subInfo.data.cancel_at_period_end ? (
          <div>
            <div className="title">Enjoy your access!</div>
            <div className="content-succes-msg">
              Your promo code has been successfully added.
            </div>
            <div className="content-succes-msg content-success-msg-btm-margin">
              Your subscription will expire on:
            </div>
            {subInfo.data ? (
              <div className="content-expire-date">
                {moment
                  .unix(subInfo.data.current_period_end)
                  .format('MM/DD/YYYY')}
              </div>
            ) : null}
          </div>
        ) : (
          <div>
            <div className="title">Subscription Extended</div>
            <div className="content-succes-msg">
              Your promotion has been added.
            </div>
            <div className="content-succes-msg content-success-msg-btm-margin">
              Your subscription has been extended to:
            </div>
            {subInfo.data ? (
              <div className="content-expire-date">
                {moment
                  .unix(subInfo.data.current_period_end)
                  .format('MM/DD/YYYY')}
              </div>
            ) : null}
          </div>
        )}
        <div
          className="close-btn"
          onClick={() => props.setShowPromoSuccessModal(false)}>
          Close
        </div>
      </Modal>
    </Background>
  ) : null;
};

export default PromoSuccess;

const Background = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 2;
  border-radius: 0;
  background-color: ${props => props.theme.colors.grey_base}CF;
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  width: 450px;
  top: calc(50% - 100px);
  left: calc(50% - 225px);
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 50px 30px 35px 30px;
  box-sizing: border-box;

  @media (max-width: 450px) {
    height: 100%;
    max-width: 100%;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .close-modal {
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .title {
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 17px;
  }

  .content {
    &-success-msg {
      font-size: 16px;
      line-height: 1.25;

      &-btm-margin {
        margin-bottom: 13px;
      }
    }

    &-expire-date {
      font-size: 22px;
      line-height: 1.3;
      margin-bottom: 18px;
    }

    &-reference-sub-text {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.17;
      margin-bottom: 17px;
    }
  }

  .close-btn {
    font-size: 12px;
    font-weight: 600;
    color: ${props => props.theme.colors.white_main};
    cursor: pointer;
  }
`;
