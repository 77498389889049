import React, {Component} from 'react';
import styled from 'styled-components';
import {connect} from 'react-redux';
import lock from '../../../assets/images/lock.png';
import UniversalButton from '../UniversalButton';

class NotSubscribed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.userSub) {
      document.addEventListener('DOMContentLoaded', e => {
        let observer = new MutationObserver(records => {
          let reload = false;
          Array.isArray(records) &&
            records.forEach(record => {
              Array.isArray(record.removedNodes) &&
                record.removedNodes.forEach(node => {
                  if (this.refs.subModal === node && !this.props.userSub)
                    reload = true;
                });
              if (reload) window.location.reload(true);
            });
        });
        observer.observe(document.documentElement, {
          attributes: true,
          childList: true,
          subtree: true,
          attributeOldValue: true,
          characterData: true
        });
      });
    }
  }

  render() {
    return !this.props.showModal ? (
      <Background ref="subModal">
        <Modal>
          <Lock src={lock} alt="Lock Icon" />
          <h1 className="text-title">OOPS, JUST</h1>
          <h1 className="text-title">ONE MORE STEP </h1>
          <p className="text-body">
            These video workouts require an Inspire Fitness subscription.
          </p>
          <div className="btn-container">
            <UniversalButton
              onClick={() => {
                // TODO: change to '/subscription/plan' after sale
                window.location.replace('/subscription/payment');
              }}
              label={'Subscribe'}
            />
            <UniversalButton
              onClick={() => {
                this.props.history.push('/account/subscription', {
                  promo: true
                });
              }}
              label={'Have a promo?'}
            />
            <UniversalButton
              onClick={() => {
                this.props.history.push('/account', {
                  promo: true
                });
              }}
              label={'Have a serial number?'}
            />
          </div>
        </Modal>
      </Background>
    ) : null;
  }
}

const mapStateToProps = state => ({});

export default connect(mapStateToProps)(NotSubscribed);

const Background = styled.div`
  position: absolute;
  width: calc(100vw - 250px);
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 20;
  background-color: ${props => props.theme.colors.grey_base}CF;

  @media (max-width: 750px) {
    width: 100vw;
    bottom: 0;
    top: 35%;
  }
`;

const Modal = styled.div`
  border-radius: 2px;
  background-color: ${props => props.theme.colors.grey_4};
  position: absolute;
  max-width: 650px;
  top: 25%;
  left: 10%;
  z-index: 2;
  color: ${props => props.theme.colors.white_main};
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 750px) {
    position: absolute;
    max-width: 100%;
    top: 15vh;
    right: 0;
    left: 0;
    border-radius: 0;
  }

  .text {
    &-title {
      margin: 0 0 10px 0;
      letter-spacing: 4px;
    }
    &-body {
      font-size: 1.25rem;
      letter-spacing: 3px;
      text-align: center;
    }
  }

  .btn-container {
    display: flex;
    justify-content: space-between;
    width: 95%;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }
`;

const Lock = styled.img`
  margin: 25px 0 40px 0;
`;
