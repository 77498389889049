import React, {useState} from 'react';
import PromoCode from '../../common/Modals/PromoCode';
import PromoSuccess from '../../common/Modals/PromoSuccess';
import SerialNumber from '../../common/Modals/SerialNumber';
import SerialSuccess from '../../common/Modals/SerialSuccess';

const LoginInfo = () => {
  const [showSerialModal, setShowSerialModal] = useState(false);
  const [showSerialSuccessModal, setShowSerialSuccessModal] = useState(false);
  const [showPromoModal, setShowPromoModal] = useState(false);
  const [showPromoSuccessModal, setShowPromoSuccessModal] = useState(false);

  return (
    <div>
      <SerialNumber
        showSerialModal={showSerialModal}
        setShowSerialModal={setShowSerialModal}
        setShowSerialSuccessModal={setShowSerialSuccessModal}
      />
      <SerialSuccess
        showSerialModal={showSerialSuccessModal}
        setShowSerialSuccessModal={setShowSerialSuccessModal}
      />
      <PromoCode
        showPromoModal={showPromoModal}
        setShowPromoModal={setShowPromoModal}
        setShowPromoSuccessModal={setShowPromoSuccessModal}
      />
      <PromoSuccess
        showPromoSuccessModal={showPromoSuccessModal}
        setShowPromoSuccessModal={setShowPromoSuccessModal}
      />
    </div>
  );
};

export default LoginInfo;
