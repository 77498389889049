import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import Loader from './Loader';
import NotSubscribed from '../common/Modals/NotSubscribed';
import {setUser} from '../../redux/actions/user';
import {useHistory} from 'react-router-dom';
import {user} from '../../utilities/apiCalls';
import {logInfo} from '../../utilities/logger';

const ProtectedRoutes = (props, ...rest) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const reduxUser = useSelector(state => state.user);
  const [validated, setValidated] = useState(false);
  const Component = props.component;
  const notSubscribed = reduxUser?.subscription?.active;

  useEffect(() => {
    const handleInvalidate = () => {
      user.invalidate().then(async () => {
        dispatch(setUser({}));
        history.push('/login');
      });
    };

    const getAndSetUser = async userId => {
      const userInfo = await user.getUserInfo(userId);
      const haveUser = !userInfo.error;
      if (haveUser) {
        dispatch(setUser(userInfo.data));
        setValidated(true);
      } else {
        handleInvalidate();
      }
    };

    user.validate().then(validateRes => {
      const validated = !validateRes.error;
      logInfo('validated: ', validated);

      if (validated) {
        const noUserInRedux = Object.keys(reduxUser).length <= 1;
        logInfo('noUserInRedux: ', noUserInRedux);
        if (noUserInRedux) {
          getAndSetUser(validateRes.data.userId);
        } else {
          setValidated(true);
        }
      } else {
        handleInvalidate();
      }
    });
  }, [reduxUser, dispatch, history]);

  return validated ? (
    <div>
      {!window.location.href.includes('account') && (
        <NotSubscribed showModal={notSubscribed} history={history} />
      )}
      <Component {...props} {...rest} />
    </div>
  ) : (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Loader size={50} color={'#ff653f'} />
    </div>
  );
};

export default ProtectedRoutes;
